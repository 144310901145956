.nav-bar .wallet-info {
    display: flex;
    align-items: center;
}

.nav-bar .wallet-info .login-role {
    position: absolute;
    right: 345px;
    font-size: 16px;
    font-weight: 600;
    color: #f9f9f9 !important;
}

.nav-bar .wallet-info .login-role span {
    font-weight: 500;
    text-transform: capitalize;
}


.nav-bar .wallet-info .address-btn {
    color: #f9f9f9;
    padding: 5px 15px;
    border-radius: 7px;
    background: #303342;
    border: 1px solid #f9f9f9;
}

.nav-bar .wallet-info .address-btn:hover {
    background: #92101F;
    color: #f9f9f9;
}

.nav-bar .wallet-info .balance-btn {
    position: absolute;
    right: 215px;
    z-index: -999;
    color: #f9f9f9;
    padding: 5px 20px;
    border-radius: 7px;
    background: #4f5057;
    border: 1px solid #f9f9f9;
}

.nav-bar .wallet-info .balance-btn:hover {
    background: #92101F;
    color: #f9f9f9;
}
