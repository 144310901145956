.mint-new {
  display: flex;
  justify-content: center;
}

.main-container .mint-new-NFT {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
};

