.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}



/* ========== Custom Styles Whitelist Modal =========== */

.main-modal.whitelist-modal {
  max-width: 900px !important;
}

.main-modal.whitelist-modal .modal-content .modal-body {
  padding: 10px 150px;
}

.whitelist-modal .modal-content .modal-header hr {
  border-top: 1px solid #ffffff;
}

.whitelist-modal .text-field .MuiFormLabel-root.Mui-focused,
.whitelist-modal .text-field .MuiFormLabel-root,
.whitelist-modal .text-field .MuiInputBase-root,
.whitelist-modal .text-field .MuiFormHelperText-root {
  color: #ffffff !important;
}

.whitelist-modal .text-field .MuiInput-underline:after,
.whitelist-modal .text-field .MuiInput-underline:before,
.whitelist-modal .text-field .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #ffffff;
}