.login-page .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
  height: 100vh;
}

.login-page .login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-page .login-area .login-form {
  padding: 50px 70px;
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 0px 7px 18px #888890;
}

.login-page .login-area .login-form .login-title {
  color: #000000;
  text-align:center;
  font-size: 28px;
}

.login-page .login-area .login-form .validator-form .login-btn {
  background-color: #92101F !important;
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 56px;
  line-height: 37px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  width: 100%; 
  padding: 7px 15px !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}


.login-page .login-area .login-form .login-text p {
  font-size: 18px;
  color: #000000;
  font-family: 'Font Awesome 5 Free';
}

.login-page .login-area .login-page-logo {
  width: 50%;
  padding: 60px 70px;
  border-radius: 20px;
  border: 2px solid #1e1e2f;
  background: #1e1e2f;
  box-shadow: 0px 7px 18px #888890;
}

/* ========== Responsive =========== */


@media screen and (max-width: 425) {
  .login-page .login-area {
    margin-top: 15% !important;
  }
}