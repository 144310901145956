
hr {
  width: 100%;
}

p {
  color: #000000 !important;
}

.Toastify__toast--success {
  background: #14A8A6;
}

/* ========== Custom Scroll Styles =========== */

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #27293d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333645;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #333645;
}


/* ========== Custom MainContainer Styles =========== */

.main-container {
    background-color: #ffffff !important;
    padding: 20px 30px !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 8px -3px !important;
    min-height: 90vh;
}

.main-container-head {
  display: flex;
  justify-content: space-between;
}

.main-container-heading {
  color: #000000;
  font-size: 18px;
  font-weight: 550;
  margin-top: 10px;
}

/* ========== Custom Table Styles =========== */

.table .rt-tbody .rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ========== Custom Slider Styles =========== */

.slider {
  padding: 30px 40px;
  width: 1175px;
}

/*========== Custom Styles View Data  ============= */

.view-data .view-data-main-title {
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 5px;
}

.view-data .view-data-body .view-data-row {
  background-color: #eaeded  ;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}

.view-data .view-data-body .view-data-row .view-data-title {
  font-weight: 600;
}

.view-data .view-data-body .view-data-sub-title {
  font-size: 20px;
  font-weight: 600;
}

.view-data .view-data-footer {
  display: flex;
  justify-content: center;
}


/*========== Custom Styles Edit-Add Slider ============= */

.edit-add {
  text-align: center;
  padding: 30px 70px;
  border-radius: 20px;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  box-shadow: 0 4px 29px rgba(47,47,47,.1);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.edit-add .edit-add-title {
padding: 15px;
font-size: 26px;
font-weight: 500;
text-align: center;
}

.edit-add .edit-add-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  }

.edit-add .edit-add-body .edit-img {
  width: 120px; 
  height: 120px; 
  border-radius: 50%;
  background: 'gray';
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiFormLabel-root.Mui-focused {
  color: #282828e0;
}

.edit-add .edit-add-body .form.edit-add-field .form-input-field
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #282828e0;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.MuiAutocomplete-option {
  color: #000000;
}

.edit-add .edit-add-body .edit-add-buttons {
display: flex;
padding: 30px 90px;
justify-content: center;
}

/* ========== Custom Button Styles =========== */

.btn-style-one {
  background-color: #92101F;
  border: 1px solid transparent;
  padding: 10px 38px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 56px;
  line-height: 37px;
  font-family: 'Fahkwang', sans-serif;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

.btn-style-one:hover {
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #92101F;
  color: #92101F;
}


.add-btn {
  color: #ffffff !important;
  padding: 5px 15px !important;
  border-radius: 10px !important;
  border: 1px solid #92101F !important;
  background-color: #92101F !important;

}

.add-btn:hover {
  color: #ffffff !important;
  background-color: #92101F !important;
  border: 1px solid #92101F !important;
}

.view-btn {
  min-width: 45% !important;
  color: #92101F !important;
  border-radius: 10px !important;
  border: 1px solid #92101F !important;
}

.view-btn:hover {
  color: #ffffff !important;
  background-color: #92101F !important;
}

.edit-btn {
  min-width: 45% !important;
  color: #92101F !important;
  border-radius: 10px !important;
  border: 1px solid #92101F !important;
}

.edit-btn:hover {
  color: #ffffff !important;
  background-color: #92101F !important;
}

.delete-btn {
  min-width: 45% !important;
  color: #e74c3c !important;
  border-radius: 10px !important;
  border: 1px solid #e74c3c !important;
}

.delete-btn:hover {
  color: #ffffff !important;
  background-color: #e74c3c !important;
}

.cancel-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #e74c3c  !important;
}

.submit-btn {
    border-radius: 10px !important;
    color: #ffffff !important;
    background: #92101F  !important;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.confirm-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #92101F  !important;
}

Button:focus {
    outline: 0px !important; 
}

/* ========== Custom AppLoader Styles =========== */

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #92101F;
}

.blue-loader {
  color: #92101F;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 500px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  background-color: #92101F !important;
  border-radius: 30px !important;
}

.main-modal .modal-content .modal-header {
  display: flex;                   
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 100%;
  padding: 10px;
  margin: 20px 0px 35px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}



/*========== Responsive ============= */

@media only screen and (max-width: 650px){

  .slider {
      padding: 30px;
      width: 500px;
  }

  .slider .edit-add {
      padding: 20px 10px;
  }

  .slider .edit-add .slider-body .edit-add-buttons {
      padding: 30px;
  }
}

@media only screen and (max-width: 425px){
  .slider {
      padding: 20px;
      width: 400px;
  }
}

@media only screen and (max-width: 375px){
  .slider {
      padding: 10px;
      width: 325px;
  }
}
